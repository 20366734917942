<template>
  <div>
    <div
      class="py-4 px-4 mx-2 d-flex justify-content-center align-items-center tree-button"
      :style="{ backgroundColor: button.bgCards }"
    >
      <i
        :class="button.icon"
        style="font-size: 24px"
      />
    </div>

    <label
      class="font-weight-bold mx-2 mt-2 w-100 text-center"
      :style="{color: button.colorFont}"
      style="font-size: 0.6rem; max-width: 80px"
    >
      <span v-if="button.categoria">
        {{ button.categoria }}
      </span>

      <span v-else>
        {{ button.nomeSubCat }}
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'TreeButton',
  props: {
    button: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.tree-button {
  border-radius: 10px;
  width: 80px;
  height: 80px;
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}
</style>
