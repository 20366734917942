<template>
  <div class="px-3">
    <h2 class="text-white ml-4 mb-4">
      Gestão de Fanbox:

      <img
        class="ml-2"
        :src="imgpath(selectedOwner)"
        height="60"
      >
    </h2>

    <p
      class="text-white"
      style="cursor: pointer"
      @click="goBackToIndex"
    >
      <i class="fa fa-arrow-left mr-2" />

      Voltar
    </p>

    <section class="py-5 bg-white">
      <div>
        <p>Ações</p>

        <button @click="openColorDialog = !openColorDialog">
          Edição em massa de cores
        </button>
      </div>

      <div
        :key="incrementKey"
        class="d-flex flex-column px-2 align-items-start"
      >
        <MenuView
          v-for="(tree, treeIdx) in configBox"
          :key="treeIdx"
          :node="tree"
          :father="configBox"
          @change-father="setNewArray"
        />
      </div>
    </section>

    <vs-popup
      title="Cores"
      :active.sync="openColorDialog"
    >
      <div class="text-left">
        <p class="mb-4">
          Bem vindo ao painel de alteração de cores em massa. As alterações
          executadas aqui serão aplicadas para todos os botões.
        </p>

        <vs-row calss="my-4 py-4">
          <vs-col vs-lg="6">
            <h3 class="mb-2">
              Cor de fundo:
            </h3>

            <div class="d-flex w-100 flex-column align-items-start">
              <vs-radio
                v-model="demoButton.bgCards"
                :vs-value="cor1"
              >
                Cor 1
              </vs-radio>

              <vs-radio
                v-model="demoButton.bgCards"
                :vs-value="cor2"
              >
                Cor 2
              </vs-radio>

              <vs-radio
                v-model="insertOtherColor"
                :vs-value="!insertOtherColor"
              >
                Outra cor:
              </vs-radio>

              <vs-input
                v-if="insertOtherColor"
                v-model="demoButton.bgCards"
              />
            </div>
          </vs-col>

          <vs-col
            vs-lg="6"
            class="d-flex justify-content-center"
          >
            <TreeButton
              class="mb-1"
              :button="demoButton"
            />
          </vs-col>
        </vs-row>
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* Components */
import MenuView from './Reusable/MenuView.vue';
import TreeButton from './Reusable/TreeButton.vue';

/* Config */
import { configDomain } from '@/global';

export default {
  name: 'MenuTree',
  components: {
    MenuView,
    TreeButton,
  },
  props: {
    selectedOwner: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    configBox: [
      {
        id: 1,
        categoria: 'MEU FANBOX',
        accessValue: ['Fanbox'],
        icon: 'fa-2x fa fa-star text-white',
        bgCards: '#0A121E',
        colorFont: '#0A121E',
        expanded: false,
        urlRedirect: '/interval',
        SubCategorias: [
          {
            id: 11,
            nomeSubCat: 'MEUS FÃS',
            icon: 'fa fa-user-circle fa-2x text-white',
            expanded: false,
            urlRedirect: '/interval',
            bgCards: '#0A121E',
            colorFont: '#0A121E',
            SubCategorias: [
              {
                id: 121,
                nomeSubCat: 'VISÃO GERAL',
                icon: 'fa fa-chart-pie fa-2x text-white',
                expanded: false,
                urlRedirect: '/dashboards/project-geral',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
              {
                id: 121,
                nomeSubCat: 'PERFIL DOS IDS',
                icon: 'fa fa-user-circle fa-2x text-white',
                expanded: false,
                urlRedirect: '/dashboards/project-box/perfilGeral',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
              {
                id: 15,
                nomeSubCat: 'SINGLE FAN VIEW',
                accessValue: ['Filtros'],
                icon: 'fa fa-id-card fa-2x colorCard',
                expanded: false,
                urlRedirect: '/IDDigital/registros',
                bgCards: '#ffff',
                colorFont: '#0A121E',
              },
            ],
          },
          {
            id: 12,
            nomeSubCat: 'PESQUISAS E PROJETOS DIGITAIS',
            icon: 'fas fa-mobile fa-2x text-white',
            expanded: false,
            urlRedirect: '/interval',
            bgCards: '#0A121E',
            colorFont: '#0A121E',
            SubCategorias: [
              {
                id: 121,
                nomeSubCat: 'PERFIL DOS PARTICIPANTES',
                icon: 'fa fa-user-circle fa-2x text-white',
                expanded: false,
                urlRedirect: '/dashboards/project-box/perfilPessoal',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
              {
                id: 122,
                nomeSubCat: 'RELAÇÃO COM O ESPORTE',
                icon: 'fa fa-car fa-2x text-white',
                expanded: false,
                urlRedirect: '/dashboards/project-box/relacaoEsporte',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
              {
                id: 123,
                nomeSubCat: 'RELAÇÃO COM A STOCK CAR',
                icon: 'fa fa-flag-checkered fa-2x text-white',
                expanded: false,
                urlRedirect: '/interval',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
                SubCategorias: [
                  {
                    id: 1231,
                    nomeSubCat: 'PILOTOS E EQUIPES',
                    icon: 'fa fa-users fa-2x text-white',
                    expanded: false,
                    urlRedirect: '/dashboards/project-box/pilotosequipes',
                    bgCards: '#0A121E',
                    colorFont: '#0A121E',
                  },
                  {
                    id: 1232,
                    nomeSubCat: 'CONSUMO DE CONTEUDO',
                    icon: 'fa fa-credit-card fa-2x text-white',
                    expanded: false,
                    urlRedirect: '/dashboards/project-box/consumostock',
                    bgCards: '#0A121E',
                    colorFont: '#0A121E',
                  },
                  {
                    id: 1233,
                    nomeSubCat: 'PRESENÇA NO AUTÓDROMO',
                    icon: 'fa fa-map-marker-alt fa-2x text-white',
                    expanded: false,
                    urlRedirect: '/dashboards/project-box/autodromotock',
                    bgCards: '#0A121E',
                    colorFont: '#0A121E',
                  },
                ],
              },
              {
                id: 124,
                nomeSubCat: 'PERFIL DIGITAL',
                icon: 'fa fa-mobile-alt fa-2x text-white',
                expanded: false,
                urlRedirect: '/dashboards/project-box/perfilDigital',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
              {
                id: 125,
                nomeSubCat: 'MARCAS E CONSUMO',
                icon: 'fa fa-chart-pie fa-2x text-white',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
                expanded: false,
                urlRedirect: '/interval',
                SubCategorias: [
                  {
                    id: 1251,
                    nomeSubCat: 'ESPORTE',
                    icon: 'fa fa-futbol fa-2x text-white',
                    expanded: false,
                    urlRedirect: '/dashboards/project-box/marcasesporte',
                    bgCards: '#0A121E',
                    colorFont: '#0A121E',
                  },
                  {
                    id: 1252,
                    nomeSubCat: 'SERVIÇOS FINANCEIROS',
                    icon: 'fa fa-money-check-alt fa-2x text-white',
                    expanded: false,
                    urlRedirect: '/dashboards/project-box/servicosfinanceiros',
                    bgCards: '#0A121E',
                    colorFont: '#0A121E',
                  },
                  {
                    id: 1253,
                    nomeSubCat: 'BENS DE CONSUMO',
                    icon: 'fa fa-tag fa-2x text-white',
                    expanded: false,
                    urlRedirect: '/dashboards/project-box/bensdeconsumo',
                    bgCards: '#0A121E',
                    colorFont: '#0A121E',
                  },
                  {
                    id: 1254,
                    nomeSubCat: 'OUTROS SERVICOS',
                    icon: 'fa fa-car-side fa-2x text-white',
                    expanded: false,
                    urlRedirect: '/dashboards/project-box/outrosservicos',
                    bgCards: '#0A121E',
                    colorFont: '#0A121E',
                  },
                ],
              },
            ],
          },
          {
            id: 13,
            nomeSubCat: 'MEUS NEGÓCIOS',
            accessValue: ['Meus negócios'],
            icon: 'fa fa-briefcase fa-2x text-white',
            bgCards: '#0A121E',
            colorFont: '#0A121E',
            expanded: false,
            urlRedirect: '/interval',
            SubCategorias: [
              {
                id: 131,
                nomeSubCat: 'AUTO SERVICE',
                icon: 'fa fa-store fa-2x text-white',
                expanded: false,
                urlRedirect: '/dashboards/negocios-geral/neg-stockservices',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
              {
                id: 132,
                nomeSubCat: 'INGRESSOS',
                icon: 'fa fa-ticket-alt fa-2x text-white',
                expanded: false,
                urlRedirect: '/dashboards/negocios-geral/neg-sympla',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
              {
                id: 133,
                nomeSubCat: 'FANPUSH',
                icon: 'fas fa-mobile fa-2x text-white',
                expanded: false,
                urlRedirect: '/dashboards/negocios-geral/neg-fanpush',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
              {
                id: 134,
                nomeSubCat: 'E-COMMERCE',
                icon: 'fa fa-shopping-cart fa-2x text-white',
                expanded: false,
                urlRedirect: '/dashboards/blank',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
              {
                id: 135,
                nomeSubCat: 'MEMBERSHIP',
                icon: 'fa fa-handshake fa-2x text-white',
                expanded: false,
                urlRedirect: '/dashboards/blank',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
              {
                id: 136,
                nomeSubCat: 'OTT / STREAMING',
                icon: 'fa fa-headset fa-2x text-white',
                expanded: false,
                urlRedirect: '/dashboards/blank',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
              {
                id: 137,
                nomeSubCat: 'APP OFICIAL',
                icon: 'fa fa-mobile fa-2x text-white',
                expanded: false,
                urlRedirect: '/dashboards/blank',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
            ],
          },
          {
            id: 14,
            nomeSubCat: 'FILTROS',
            accessValue: ['Filtros'],
            icon: 'fa fa-filter fa-2x colorCard',
            expanded: false,
            urlRedirect: '/IDDigital/filtros',
            bgCards: '#ffff',
            colorFont: '#0A121E',
          },
        ],
      },
      {
        id: 2,
        categoria: 'HUB DE OPERAÇÕES',
        accessValue: ['Events', 'Campanhas', 'Surveys', 'Votações'],
        icon: 'fa fa-calendar fa-2x text-white',
        expanded: false,
        urlRedirect: '/interval',
        bgCards: '#0A121E',
        colorFont: '#0A121E',
        SubCategorias: [
          {
            id: 21,
            nomeSubCat: 'EVENTOS',
            accessValue: ['Events'],
            icon: 'fa fa-calendar fa-2x text-white',
            expanded: false,
            urlRedirect: '/events',
            bgCards: '#0A121E',
            colorFont: '#0A121E',
          },
          {
            id: 22,
            nomeSubCat: 'MINHAS CAMPANHAS',
            accessValue: ['Campanhas'],
            icon: 'fa fa-bullhorn fa-2x text-white',
            bgCards: '#0A121E',
            colorFont: '#0A121E',
            expanded: false,
            urlRedirect: '/interval',
            SubCategorias: [
              {
                id: 221,
                nomeSubCat: 'NOVA CAMPANHA',
                icon: 'fa fa-bullhorn fa-2x text-white',
                expanded: false,
                urlRedirect: '/Campanhas/criar',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
              {
                id: 222,
                nomeSubCat: 'CAMPANHAS REALIZADAS',
                icon: 'fa fa-chart-pie fa-2x text-white',
                expanded: false,
                urlRedirect: '/Campanhas/listar',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
            ],
          },
          {
            id: 23,
            nomeSubCat: 'MINHAS PESQUISAS',
            accessValue: ['Surveys'],
            icon: 'fa fa-poll fa-2x text-white',
            expanded: false,
            urlRedirect: '/survey-list',
            bgCards: '#0A121E',
            colorFont: '#0A121E',
          },
          {
            id: 24,
            categoria: 'MINHAS VOTAÇÕES',
            accessValue: ['Votações'],
            icon: 'fa fa-user-circle fa-2x text-white',
            bgCards: '#0A121E',
            colorFont: '#0A121E',
            expanded: false,
            urlRedirect: '/interval',
            SubCategorias: [
              {
                id: 241,
                nomeSubCat: 'NOVA VOTAÇÃO',
                icon: 'fa fa-bullhorn fa-2x text-white',
                expanded: false,
                urlRedirect: '/dashboards/blank',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
              },
              {
                id: 242,
                nomeSubCat: 'VOTAÇÕES REALIZADAS',
                icon: 'fa fa-chart-pie fa-2x text-white',
                expanded: false,
                urlRedirect: '/dashboards/blank',
                bgCards: '#0A121E',
                colorFont: '#0A121E',
                SubCategorias: [],
              },
            ],
          },
        ],
      },
      {
        id: 3,
        categoria: 'MINHA CONTA',
        accessValue: ['Admin'],
        icon: 'fa fa-user-circle fa-2x text-white',
        bgCards: '#0A121E',
        colorFont: '#0A121E',
        expanded: false,
        urlRedirect: '/interval',
        SubCategorias: [
          {
            id: 31,
            nomeSubCat: 'GESTÃO DE USUÁRIOS',
            accessValue: ['Admin'],
            icon: 'fa fa-users fa-2x text-white',
            expanded: false,
            urlRedirect: '/access-mgmt',
            bgCards: '#0A121E',
            colorFont: '#0A121E',
          },
        ],
      },
    ],
    demoButton: { bgCards: '', colorFont: '', categoria: 'lorem ipsum' },
    openColorDialog: false,
    insertOtherColor: false,
  }),
  computed: {
    cor1() {
      return this.$store.state.configBox.cor1;
    },
    cor2() {
      return this.$store.state.configBox.cor2;
    },
  },
  watch: {
    insertOtherColor(newValue) {
      if (!newValue) return;
      this.demoButton.bgCards = '';
    },
  },
  methods: {
    goBackToIndex() {
      this.$router.push({
        name: 'FanboxManagement',
        params: { selectedOwner: this.selectedOwner },
      });
    },
    setNewArray(newArray) {
      this.configBox = newArray;
    },
    imgpath(id) {
      return `${configDomain}/sys/101/${id}/logo.png`;
    },
  },
};
</script>

<style>
.big-plus-create {
  font-size: 24px;
  cursor: pointer;
}

.big-plus-create:hover {
  color: green;
}
</style>
