<template>
  <div
    :key="incrementKey"
    class="d-flex align-items-start mt-5"
  >
    <button
      class="delete-btn mt-4"
      @click="openDeletionDialog = true"
    >
      <i
        class="text-danger fa fa-times mt-1"
        style="font-size: 18px"
      />
    </button>

    <div>
      <TreeButton
        class="mb-1"
        :button="treeNode"
      />

      <div
        v-if="isLastNode"
        class="mt-4"
      >
        <i
          class="text-black fa fa-plus mt-2 big-plus-create"
          style="margin-left: 36px;"
          @click="createNewSibling"
        />
      </div>
    </div>

    <div class="d-flex flex-column">
      <button
        v-if="treeNode.SubCategorias && treeNode.SubCategorias.length"
        class="rounded-btn mt-2"
        @click="toggleExpand"
      >
        <i
          class="text-white fa mt-1"
          :class="treeNode.expanded ? ' fa-chevron-left' : ' fa-chevron-right'"
          style="font-size: 8px"
        />
      </button>

      <button
        v-else
        class="rounded-btn mt-2 green-hover"
        @click="createNewChild"
      >
        <i
          class="text-white fa fa-plus mt-0"
          style="font-size: 8px;"
        />
      </button>

      <button
        class="rounded-btn mt-2"
        @click="openNodeDialog = true"
      >
        <i
          class="text-white fa fa-pen mt-1"
          style="font-size: 8px"
        />
      </button>
    </div>

    <div
      v-if="treeNode.SubCategorias && treeNode.SubCategorias.length && treeNode.expanded"
      class="d-flex align-items-start"
    >
      <div class="mt-4 ml-2 custom-divider" />

      <!-- Sub Menu -->
      <div
        class="d-flex flex-column px-2 align-items-start"
        style="border-left: 1px solid black"
      >
        <TreeView
          v-for="(child, i) in treeNode.SubCategorias"
          :key="i"
          :node="child"
          :father="treeNode.SubCategorias"
          @changeFather="setNewArray"
        />
      </div>
    </div>

    <vs-popup
      title="Editar botão"
      :active.sync="openNodeDialog"
    >
      <div class="my-2 d-flex justify-content-center">
        <TreeButton
          class="mb-1"
          :button="treeNode"
        />
      </div>

      <vs-row>
        <vs-col vs-lg="6">
          <label class="small font-weight-bold mt-2">
            Nome do botão:
          </label>

          <vs-input
            v-model="treeNode.categoria"
            placeholder="Nome do botão"
            class="w-100"
          />
        </vs-col>

        <vs-col vs-lg="6">
          <label class="small font-weight-bold mt-2">
            ícone:
          </label>

          <vs-input
            v-model="treeNode.icon"
            placeholder="Apelido"
            class="w-100"
          />
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col vs-lg="6">
          <label class="small font-weight-bold mt-2">
            Rota do botão:
          </label>

          <vs-input
            v-model="treeNode.urlRedirect"
            placeholder="Rota"
            class="w-100"
          />
        </vs-col>

        <vs-col vs-lg="3">
          <label class="small font-weight-bold mt-2">
            Cor do botão:
          </label>

          <vs-input
            v-model="treeNode.bgCards"
            placeholder="Cor do botão"
            class="w-100"
          />
        </vs-col>

        <vs-col vs-lg="3">
          <label class="small font-weight-bold mt-2">
            Cor do texto:
          </label>

          <vs-input
            v-model="treeNode.colorFont"
            placeholder="Cor do texto"
            class="w-100"
          />
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup
      title="Confirmação"
      :active.sync="openDeletionDialog"
    >
      <div class="text-center">
        <p>
          Se você apagar esse menu, todos os menus aninhados serão perdidos.
          Deseja confirmar a ação?
        </p>

        <vs-button
          color="red"
          class="mx-auto"
          @click="removeNode"
        >
          Confirmar
        </vs-button>
      </div>
    </vs-popup>

    <vs-popup
      title="Criar novo botão"
      :active.sync="openNewNodeDialog"
    >
      <div class="my-2 d-flex justify-content-center">
        <TreeButton
          class="mb-1"
          :button="newNode"
        />
      </div>

      <vs-row>
        <vs-col vs-lg="6">
          <label class="small font-weight-bold mt-2">
            Nome do botão:
          </label>

          <vs-input
            v-model="newNode.categoria"
            placeholder="Nome do botão"
            class="w-100"
          />
        </vs-col>

        <vs-col vs-lg="6">
          <label class="small font-weight-bold mt-2">
            ícone:
          </label>

          <vs-input
            v-model="newNode.icon"
            placeholder="Apelido"
            class="w-100"
          />
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col vs-lg="6">
          <label class="small font-weight-bold mt-2">
            Rota do botão:
          </label>

          <vs-input
            v-model="newNode.urlRedirect"
            placeholder="Rota"
            class="w-100"
          />
        </vs-col>

        <vs-col vs-lg="3">
          <label class="small font-weight-bold mt-2">
            Cor do botão:
          </label>

          <vs-input
            v-model="newNode.bgCards"
            placeholder="Cor do botão"
            class="w-100"
          />
        </vs-col>

        <vs-col vs-lg="3">
          <label class="small font-weight-bold mt-2">
            Cor do texto:
          </label>

          <vs-input
            v-model="newNode.colorFont"
            placeholder="Cor do texto"
            class="w-100"
          />
        </vs-col>
      </vs-row>

      <div class="w-100 d-flex justify-content-end mt-3">
        <vs-button
          class="mr-2"
          @click="openNewNodeDialog = false"
        >
          Cancelar
        </vs-button>

        <vs-button
          color="#66ff66"
          text-color="black"
          @click="saveNewNode"
        >
          <span>Salvar</span>
        </vs-button>
      </div>
    </vs-popup>
  </div>
</template>

<script>
/* Components */
import TreeButton from './TreeButton.vue';

export default {
  name: 'TreeView',
  components: {
    TreeButton,
  },
  props: {
    node: {
      type: Object,
      default: () => ({}),
    },
    father: {
      type: Array,
      default: () => ([]),
    },
  },
  data: () => ({
    treeNode: {},
    openNodeDialog: false,
    openDeletionDialog: false,
    openNewNodeDialog: false,
    newNode: {},
    createSibling: false,
    createChild: false,
  }),
  computed: {
    categoria() {
      return this.treeNode.categoria;
    },
    nomeSubCat() {
      return this.treeNode.nomeSubCat;
    },
    isLastNode() {
      let length = this.father.length;

      let index = this.father.indexOf(this.treeNode);

      if (index == length - 1) return true;

      return false;
    },
    incrementKey() {
      return this.$store.state.incrementKey;
    },
  },
  watch: {
    categoria(newValue) {
      this.treeNode.nomeSubCat = newValue;
    },
    nomeSubCat(newValue) {
      this.treeNode.categoria = newValue;
    },
    openNewNodeDialog(newValue) {
      if (newValue) return;

      this.createSibling = false;

      this.createChild = false;

      this.newNode = {};
    },
  },
  created() {
    this.treeNode = this.node;
  },
  methods: {
    toggleExpand() {
      this.treeNode.expanded = !this.treeNode.expanded;

      this.$store.commit('INCREMENT_key');
    },
    removeNode() {
      let newArray = this.father.filter((menu) => {
        return menu != this.treeNode;
      });

      this.$emit('change-father', newArray);

      this.$store.commit('INCREMENT_key');

      this.openDeletionDialog = false;
    },
    setNewArray(newArray) {
      this.treeNode.SubCategorias = newArray;
    },
    createNewChild() {
      this.newNode = {};

      this.openNewNodeDialog = !this.openNewNodeDialog;

      this.createChild = true;
    },
    createNewSibling() {
      this.newNode = {};

      this.openNewNodeDialog = !this.openNewNodeDialog;

      this.createSibling = true;
    },
    saveNewNode() {
      this.newNode.expanded = false;

      if (this.createChild) this.saveNewChild();

      if (this.createSibling) this.saveNewSibling();
    },
    saveNewChild() {
      this.treeNode.SubCategorias = [];

      this.treeNode.SubCategorias.push(this.newNode);

      this.treeNode.expanded = true;

      this.openNewNodeDialog = false;

      this.$store.commit('INCREMENT_key');
    },
    saveNewSibling() {
      let newArray = this.father;

      newArray.push(this.newNode);

      this.$emit('change-father', newArray);

      this.openNewNodeDialog = false;

      this.$store.commit('INCREMENT_key');
    },
  },
};
</script>

<style scoped>
.rounded-btn {
  background-color: #8b8b8b;
  border: 0px solid #8b8b8b;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}

.custom-divider {
  border-bottom: 1px solid black;
  width: 40px;
}

.delete-btn {
  background-color: transparent;
  border: 0px solid white;
  border-radius: 50%;
  height: 25px;
  width: 25px;
}

.green-hover:hover {
  background-color: green;
}
</style>
