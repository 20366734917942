import { render, staticRenderFns } from "./MenuView.vue?vue&type=template&id=64f76270&scoped=true&"
import script from "./MenuView.vue?vue&type=script&lang=js&"
export * from "./MenuView.vue?vue&type=script&lang=js&"
import style0 from "./MenuView.vue?vue&type=style&index=0&id=64f76270&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64f76270",
  null
  
)

export default component.exports